import styled from "styled-components";
import Nav from "../../components/Nav";
import HeadSection from "../../components/HeadSection";
import PictureField from "../../components/PictureField";
import Footer from "../../components/Footer";
import OurApproach from "../../components/OurApproach";
const Main = styled.div``;
function notreImpact() {
  return (
    <Main>
      <Nav />
      <HeadSection title="Notre Impact" />
      <OurApproach />
      <Footer />
    </Main>
  );
}
export default notreImpact;
