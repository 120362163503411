import styled, { keyframes } from "styled-components";
import refugees from "../../assets/refugees.jpg";
import playImage from "../../assets/playIcon.png";
import giving from "../../assets/donate.png";
import {
  grayForParagraph,
  grayForTitle,
  primaryColor,
  secondaryColor,
} from "../../utilities/Colors";
const inOut = keyframes`
   0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;
const Main = styled.div`
  color: ${grayForTitle};
  margin-bottom: 50px;
`;
const Container = styled.div`
  width: 50%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  gap: 50px;
  margin: auto;
`;
const ContainerImage = styled.div`
  width: 100%;
  position: relative;
`;
const Image = styled.img`
  position: relative;
  width: 100%;
  z-index: -2;
`;
const ContainerVideo = styled.div`
  width: 40%;
  margin: auto;
  padding-top: 28%;
  position: absolute;
`;
const Video = styled.div`
  background-color: ${secondaryColor};
  width: 100px;
  height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 160px;
  left: -20px;
  &:hover {
    background-color: ${primaryColor};
    cursor: pointer;
  }
  &::before {
    content: "";
    width: 100px;
    height: 100px;
    border-color: ${secondaryColor};
    border-style: solid;
    position: absolute;
    border-radius: 100px;
    z-index: -1;
    animation: ${inOut} 2s ease-in-out infinite both;
    opacity: 0.5;
  }
  &::after {
    content: "";
    width: 100px;
    height: 100px;
    border-color: ${secondaryColor};
    border-style: solid;
    position: absolute;
    border-radius: 100px;
    z-index: -2;
    animation: ${inOut} 2s 1s ease-in-out infinite both;
    opacity: 0.4;
  }
`;
const PlayImage = styled.img`
  width: 100%;
  position: absolute;
  left: 70%;
`;
const Text = styled.div`
  color: ${grayForParagraph};
`;
const Title = styled.h1`
  color: ${grayForTitle};
`;
const MainText = styled.div``;
const DonateReminder = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  padding-right: 20px;
  margin-top: 40px;
  padding-left: 20px;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
  background-color: #b5a64331;
  border-radius: 10px;
`;
const ImageDonation = styled.div`
  width: 40px;
`;
const Donate = styled.img`
  width: 100%;
`;
const TextDonation = styled.p``;
const SupportersField = styled.div``;
const SectionNumbers = styled.div``;
const SectionText = styled.div``;

function OurApproach() {
  return (
    <Main>
      <Container>
        <ContainerImage>
          <Image src={refugees} alt=" Image of Young refugees" />
          <a href="https://www.youtube.com/watch?v=8rlkAWKf9Eo">
            {" "}
            <Video>
              <ContainerVideo>
                <PlayImage src={playImage} alt="Image of play Icon" />
              </ContainerVideo>
            </Video>
          </a>
        </ContainerImage>
        <Text>
          <Title>Notre Impact et Contributions Positives</Title>
          <MainText>
            À la fondation Aksanti Kabuzi Paul, notre mission est de créer un
            changement positif et durable pour les communautés de la République
            Démocratique du Congo. Depuis notre création en 2020, nous nous
            engageons à répondre aux besoins critiques à travers des solutions
            innovantes, des efforts collaboratifs et un engagement sans faille.
            Nous croyons fermement que chaque individu mérite un accès équitable
            à des ressources essentielles et à un environnement sûr et prospère.
          </MainText>
          <DonateReminder>
            <ImageDonation>
              <Donate src={giving} alt="Icon showing a giving hand" />
            </ImageDonation>
            <TextDonation>
              Participer à cette noble cause, vous avez la possibilité de
              contribuer.
            </TextDonation>
          </DonateReminder>
          <SupportersField>
            <SectionNumbers></SectionNumbers>
            <SectionText> </SectionText>
          </SupportersField>
        </Text>
      </Container>
    </Main>
  );
}
export default OurApproach;
