import styled from "styled-components";
import HeadSection from "../../components/HeadSection";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
const Main = styled.div``;
function Contact() {
  return (
    <Main>
      <Nav />
      <HeadSection title="Contactez-nous" />
      <Footer />
    </Main>
  );
}
export default Contact;
