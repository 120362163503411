import camp1 from "../assets/Fisrt/camps/camp1.png";
import camp2 from "../assets/Fisrt/camps/camp2.jpg";
import camp3 from "../assets/Fisrt/camps/camp3.jpg";
import camp4 from "../assets/Fisrt/camps/camp4.jpg";
import camp5 from "../assets/Fisrt/camps/camp5.jpg";
import camp6 from "../assets/Fisrt/camps/camp6.jpg";
import camp7 from "../assets/Fisrt/camps/camp7.jpg";
import camp8 from "../assets/Fisrt/camps/camp8.jpg";
import camp9 from "../assets/Fisrt/camps/camp9.jpg";
import camp10 from "../assets/Fisrt/camps/camp10.jpg";
import camp11 from "../assets/Fisrt/camps/camp11.jpg";
import finalistes1 from "../assets/Fisrt/finalist activity/finalistes2.jpg";
import finalistes2 from "../assets/Fisrt/finalist activity/finalistes3.jpg";
import finalistes3 from "../assets/Fisrt/finalist activity/finalistes4.jpg";
import finalistes4 from "../assets/Fisrt/finalist activity/finalistes5.jpg";
import finalistes5 from "../assets/Fisrt/finalist activity/finalistes6.jpg";
import finalistes6 from "../assets/Fisrt/finalist activity/finalistes7.jpg";
import finalistes7 from "../assets/Fisrt/finalist activity/finalistes8.jpg";
import finalistes8 from "../assets/Fisrt/finalist activity/finalistes9.jpg";
import majengo1 from "../assets/Fisrt/Meeting com/majengo1.jpg";
import majengo2 from "../assets/Fisrt/Meeting com/majengo2.jpg";
import majengo3 from "../assets/Fisrt/Meeting com/majengo3.jpg";
import majengo4 from "../assets/Fisrt/Meeting com/majengo4.jpg";
import projet1 from "../assets/Fisrt/project corner st/projet1.jpg";
import projet2 from "../assets/Fisrt/project corner st/projet2.jpg";
import projet3 from "../assets/Fisrt/project corner st/projet 3.jpg";
import projet4 from "../assets/Fisrt/project corner st/projet4.jpg";
import projet5 from "../assets/Fisrt/project corner st/projet5.jpg";
import eau1 from "../assets/Fisrt/Project Eau majengo/eau.png";
import eau2 from "../assets/Fisrt/Project Eau majengo/eau2.png";
import eau3 from "../assets/Fisrt/Project Eau majengo/eau3.png";
import eau4 from "../assets/Fisrt/Project Eau majengo/eau4.png";

const Album = [
  {
    title: "Camps",
    images: [
      camp1,
      camp2,
      camp3,
      camp4,
      camp5,
      camp6,
      camp7,
      camp8,
      camp9,
      camp10,
      camp11,
    ],
  },
  {
    title: "Activité des finalistes",
    images: [
      finalistes1,
      finalistes2,
      finalistes3,
      finalistes4,
      finalistes5,
      finalistes6,
      finalistes7,
      finalistes8,
    ],
  },
  {
    title: "Projet des jeunes ",
    images: [projet1, projet2, projet3, projet4, projet5],
  },
  {
    title: "Rencontre avec la communauté",
    images: [majengo1, majengo2, majengo3, majengo4],
  },

  {
    title: "Racordement en eau Majengo",
    images: [eau1, eau2, eau3, eau4],
  },
];

export default Album;
